import generateQuery from '@/libs/generate-query'
import HttpRequest from './HttpRequest'

class FavoriteEmailProvider extends HttpRequest {
  findAll () {
    return this.get('/favorite-email')
  }

  findByUserId (userId, page = 1, limit = 10, query) {
    return this.get(`/favorite-email/${userId}/user?page=${page}&limit=${limit}${generateQuery(query)}`)
  }

  create (payload) {
    return this.post('/favorite-email', payload)
  }

  update (id, payload) {
    return this.patch(`/favorite-email/${id}`, payload)
  }

  deleteFavorite (id) {
    return this.delete(`/favorite-email/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/favorite-email?page=${page}&limit=${limit}${generateQuery(query)}`)
  }
}

export default FavoriteEmailProvider
