<template>
  <div>
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <FavoriteEmailAdd
      ref="favoriteEmailAdd"
      :is-sidebar-active.sync="isSidebarActive"
      @add-favorite-email="addFavoriteEmail($event)" />
    <FavoriteEmailUpdate
      :is-sidebar-active.sync="isSidebarDetailActive"
      :initial-favorite-email.sync="initialFavoriteEmail"
      :selected-item.sync="selectedItem"
      @update:favorite-email="updateFavoriteEmail($event)"
      @delete-item="openDeleteModal($event)" />
    <FavoriteEmailFilter
      :search-favorite-email.sync="filter.search"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Favorite Email"
        @active-sidebar="activeSidebar()"
        @input="fetchFilter()" />
      <b-table
        striped
        hover
        responsive
        :items="items"
        :fields="fields"
        @sort-changed="sortFilter($event)"
        @row-clicked="rowClick($event)">
        <template #cell(code)="data">
          <span class="font-weight-semibold text-primary">#{{ data.value }}</span>
        </template>
        <template #cell(name)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters} from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FavoriteEmailProvider from '@/resources/FavoriteEmailProvider'
import DeleteModal from '@/components/DeleteModal.vue'
import FavoriteEmailAdd from './FavoriteEmailAdd.vue'
import FavoriteEmailUpdate from './FavoriteEmailUpdate.vue'
import FavoriteEmailFilter from './FavoriteEmailFilter.vue'
import TableTop from '../../components/TableTop.vue'

const FavoriteEmailService = new FavoriteEmailProvider()

export default {
  components: {
    FavoriteEmailAdd,
    FavoriteEmailUpdate,
    FavoriteEmailFilter,
    DeleteModal,
    TableTop
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profileData: 'User/user',
    })
  },
  data () {
    return {
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      filter: {
        search: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' }
      ],
      items: [],
      selectedItem: {},
      initialFavoriteEmail: {},
      deleteItem: {}
    }
  },
  async mounted () {
    await this.getFavoriteEmail()
  },
  methods: {
    async getFavoriteEmail () {
      try {
        const data = await FavoriteEmailService.findByUserId(this.profileData.id,
          this.pagination.page,
          this.pagination.limit, {
            search: this.filter.search
          })
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      }
    },
    async addFavoriteEmail (val) {
      try {
        await FavoriteEmailService.create({
          userId: this.profileData.id,
          email: val?.email,
          name: val?.name
        })
        await this.getFavoriteEmail()
        this.resetFormAndToggleSideBar(this.$refs.favoriteEmailAdd)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Record Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Favorite Email has been added'
          }
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Favorite Email has been Failed ${ err?.message }`
          }
        })
      }
    },
    async updateFavoriteEmail (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await FavoriteEmailService.update(val.id, payload)
        await this.getFavoriteEmail()
        this.setInitialUserTeamAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Favorite Email has been updated'
          }
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Update Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Favorite Email has been Failed'
          }
        })
      }
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await FavoriteEmailService.deleteFavorite(val.id)
        await this.getFavoriteEmail()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Favorite Email has been deleted'
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    // async confirmDelete (val) {
    //   try {
    //     this.isSidebarDetailActive = false
    //     await FavoriteEmailService.softDelete(val.UserTeamId)
    //     await this.getFavoriteEmail()
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Deleted Successfully',
    //         icon: 'EditIcon',
    //         variant: 'success',
    //         text: `👋 Favorite Email ${this.deleteItem.Name} has been deleted`
    //       }
    //     })
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },
    // async confirmDelete (val) {
    //   try {
    //     this.isSidebarDetailActive = false
    //     await FavoriteEmailService.softDelete(val.UserTeamId)
    //     await this.getFavoriteEmail()
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Deleted Successfully',
    //         icon: 'EditIcon',
    //         variant: 'success',
    //         text: `👋 Favorite Email ${this.deleteItem.Name} has been deleted`
    //       }
    //     })
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },
    activeSidebar () {
      this.isSidebarActive = true
    },
    rowClick (val) {
      this.setInitialUserTeamAndSelectItem(val)
      this.isSidebarDetailActive = true
    },
    setInitialUserTeamAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialFavoriteEmail = { ...val }
    },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    fetchFilter () {
      this.setPagination(1)
      this.getFavoriteEmail()
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getFavoriteEmail()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
</style>
